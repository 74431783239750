import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import trangleOrange from "../../images/shap/trangle-orange.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/circle-dots.png";

// import teamMember1 from "../../images/team/member1.jpg";
// import teamMember2 from "../../images/team/member2.jpg";
// import teamMember3 from "../../images/team/member3.jpg";
// import teamMember4 from "../../images/team/member4.jpg";
// import teamMember5 from "../../images/team/member5.jpg";
// import teamMember6 from "../../images/team/member6.jpg";

import teamMember1 from "../../images/gallery/clinicStaffAndInterior/teamAN.JPG";
import teamMember2 from "../../images/gallery/clinicStaffAndInterior/teamDN.JPG";
import teamMember3 from "../../images/gallery/clinicStaffAndInterior/teamEN.JPG";
import teamMember4 from "../../images/gallery/clinicStaffAndInterior/teamCN.JPG";
import teamMember5 from "../../images/gallery/clinicStaffAndInterior/teamBN.JPG";
import teamMember6 from "../../images/gallery/clinicStaffAndInterior/teamFN.jpeg";
import teamMember7 from "../../images/gallery/clinicStaffAndInterior/teamGN.jpeg";
import { team } from "../../data/team";
// import teamMember4 from "../../images/team/member4.jpg";
// import teamMember5 from "../../images/team/member5.jpg";
// import teamMember6 from "../../images/team/member6.jpg";

// Team Content
const teamMembers = [
  {
    thumb: teamMember1,
    title: "Dr. Partheeban",
    subtitle: "Dentist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember2,
    title: "Dr. Mahfuz Riad",
    subtitle: "Chiropractor",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember3,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember4,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember5,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember6,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember7,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  // {
  // 	thumb: teamMember4,
  // 	title: "Dr. Addition Smith",
  // 	subtitle: "Dentist",
  // 	socialMedia: [
  // 		{ platform: 'Twitter', link: 'https://twitter.com/' },
  // 		{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
  // 		{ platform: 'Instagram', link: 'https://www.instagram.com/' },
  // 	],
  // },
  // {
  // 	thumb: teamMember5,
  // 	title: "Dr. Mahfuz Riad",
  // 	subtitle: "Chiropractor",
  // 	socialMedia: [
  // 		{ platform: 'Twitter', link: 'https://twitter.com/' },
  // 		{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
  // 		{ platform: 'Instagram', link: 'https://www.instagram.com/' },
  // 	],
  // },
  // {
  // 	thumb: teamMember6,
  // 	title: "Dr. David Benjamin",
  // 	subtitle: "Cardiologist",
  // 	socialMedia: [
  // 		{ platform: 'Twitter', link: 'https://twitter.com/' },
  // 		{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
  // 		{ platform: 'Instagram', link: 'https://www.instagram.com/' },
  // 	],
  // },
];
const Team = () => {
  const [show, setShow] = useState(false);
  const [teamMember, setTeamMember] = useState({
    image: "",
    name: "",
    role: "",
    description: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
					Launch demo modal
				</Button> */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center ">
            <div className="team-media">
              <img
                src={teamMember.image}
                className="rounded-1"
                alt=""
                style={{ maxHeight: "200px" }}
              />
            </div>
            <div className="team-info">
              <div className="team-info-comntent">
                <h4 className="title text-center my-3">{teamMember.name}</h4>
                <span className="text-secondary">{teamMember.role}</span>
                <p>{teamMember?.description}</p>
                <ul>
                  {teamMember?.professionalAchievements?.map((item) => (
                    <li style={{ listStyle: "inside" }}>{item}</li>
                  ))}
                </ul>
              </div>
              <ul className="social-media mt-3">
                {teamMember?.socialMedia?.map((social, index) => (
                  <li key={index}>
                    <a rel="noreferrer" target="_blank" href={social.link}>
                      <i
                        className={`fab fa-${social.platform.toLowerCase()}`}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Our Team</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Our Team
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/* <img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/> */}
          </div>
        </div>

        <section className="section-area section-sp1 team-wraper">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              {team.map((teamMember, index) => (
                <div key={index} className="col-lg-4 col-sm-6">
                  <div className="team-member mb-30">
                    <div className="team-media">
                      <img src={teamMember.image} alt="" />
                    </div>
                    <div className="team-info">
                      <div className="team-info-comntent">
                        <h4 className="title">{teamMember.name}</h4>
                        <span className="text-secondary">
                          {teamMember.specialization}
                        </span>
                      </div>
                      <ul className="social-media mt-3">
                        {teamMember?.socialMedia?.map((social, index) => (
                          <li key={index}>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={social.link}
                            >
                              <i
                                className={`fab fa-${social.platform.toLowerCase()}`}
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleShow();
                          setTeamMember(teamMember);
                        }}
                        className="mt-2"
                      >
                        Know More
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <img className="pt-img1 animate1" src={trangleOrange} alt=""/>
						
						<img className="pt-img2 animate2" src={squareDotsOrange} alt=""/>
						
						<img className="pt-img3 animate-rotate" src={lineCircleBlue} alt=""/>
						
						<img className="pt-img4 animate-wave" src={waveBlue} alt=""/>
						
						<img className="pt-img5 animate-wave" src={plusBlue} alt=""/> */}
        </section>
      </div>
    </>
  );
};

export default Team;
