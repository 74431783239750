import image1 from "../../src/images/services/Counselling Services.jpg";
import image2 from "../../src/images/services/Psychological Assessment.jpg";
import image3 from "../../src/images/services/Developmental Screening.jpg";
import image4 from "../../src/images/services/ketamine-therapy.jpg";
import image5 from "../../src/images/services/Occupational Therapy.jpg";
import image6 from "../../src/images/services/Convulsive Therapy.jpg";
import image7 from "../../src/images/services/family counselling.jpg";
import image8 from "../../src/images/services/Stress Management.jpg";
import image9 from "../../src/images/services/Therapy for Sexual Dysfunction.jpg";
import image10 from "../../src/images/services/Pre-Marital Counselling.jpg";
import image11 from "../../src/images/services/Anger Management.jpg";
import image12 from "../../src/images/services/Grief Therapy.jpg";
import image13 from "../../src/images/services/Integrated Yoga Therapy.jpg";
import image14 from "../../src/images/services/Inpatient De-Addiction Facility.jpg";
import image15 from "../../src/images/services/Neuropsychological Assessment.jpg";
import image16 from "../../src/images/services/Cognitive Behavioral Therapy (CBT).jpg";

export const services = [
  {
    name: "Counselling Services",
    desc: "Our professional counselling services address a spectrum of mental health issues and disorders. Whether you're dealing with depression, anxiety, OCD, schizophrenia, de-addiction, gambling, gaming disorder, insomnia, or more, our dedicated team is here to provide support.",
    image: image1,
    subtitle:
      "Depression, Anxiety, OCD, Schizophrenia, De-Addiction, Gambling and Gaming Disorder, Insomnia, and more",
    faqDesc: "Frequently Asked Questions about Counselling Services",
    faqs: [
      {
        question: "What types of therapy approaches do you use in counselling?",
        answer:
          "We utilize various evidence-based approaches such as CBT, psychotherapy, and mindfulness techniques tailored to individual needs.",
      },
      {
        question:
          "Can I bring a family member or friend to my counselling session?",
        answer:
          "Yes, if it's beneficial for your sessions, you can have someone accompany you with your counselor's agreement.",
      },
    ],
    link: "counselling-services",
    points: [
      "Holistic Approach",
      "Individualized Care",
      "Evidence-Based Practices",
      "Confidential and Supportive",
      "Flexible Scheduling",
    ],
    usps: [
      { uspName: "Comprehensive Counseling", uspPercentage: "90%" },
      { uspName: "Client-Centered Approach", uspPercentage: "75%" },
      { uspName: "Timely and Responsive Services", uspPercentage: "80%" },
    ],
  },

  {
    name: "Psychological Assessment",
    desc: "Our comprehensive psychological assessments delve deep into understanding cognitive abilities, learning disabilities, personality traits, and neuropsychological aspects. These evaluations provide valuable insights for tailored interventions and support.",
    image: image2,
    subtitle:
      "IQ, Learning Disability, Personality Testing, Neuropsychological Assessment",
    faqDesc: "Frequently Asked Questions about Psychological Assessment",
    faqs: [
      {
        question: "How should I prepare for a psychological assessment?",
        answer:
          "There's typically no special preparation needed. Just ensure you're well-rested and have any necessary information or documents with you.",
      },
      {
        question: "Can I get immediate results after the assessment?",
        answer:
          "The assessment findings require analysis; hence, a detailed report will be provided in a subsequent session.",
      },
      {
        question:
          "What age groups do you perform psychological assessments for?",
        answer:
          "We conduct assessments for individuals of all ages, from children to adults, depending on their specific needs.",
      },

      // Add more FAQs as needed
    ],
    points: [
      "In-Depth Analysis",
      "Customized Interventions",
      "Holistic Understanding",
      "Professional Expertise",
      "Client-Centric Approach",
    ],
    usps: [
      { uspName: "Precision in Assessment", uspPercentage: "95%" },
      { uspName: "Tailored Intervention Plans", uspPercentage: "85%" },
      { uspName: "Experienced Psychologists", uspPercentage: "90%" },
      // Add more USPs as needed
    ],
    link: "psychological-assessment",
  },

  {
    name: "Developmental Screening for Children",
    desc: "Our developmental screening services focus on identifying and addressing developmental milestones in children. From early signs of speech delays to motor skill difficulties, our screenings pave the way for early interventions and optimized support.",
    image: image3,
    subtitle: "Screening for Developmental Milestones",
    faqDesc: "Frequently Asked Questions about Developmental Screening",
    faqs: [
      {
        question:
          "What are the common signs of developmental delay in children?",
        answer:
          "Signs can vary but may include delayed speech, motor skill difficulties, social interaction challenges, and learning delays.",
      },
      {
        question:
          "At what age should I seek developmental screening for my child?",
        answer:
          "Screening typically begins in infancy, but it's advisable to consult your pediatrician for guidance based on your child's development.",
      },
      {
        question: "How can developmental screening benefit my child?",
        answer:
          "Early identification allows for timely interventions and support, potentially improving long-term outcomes.",
      },
      {
        question: "Can developmental screenings diagnose specific conditions?",
        answer:
          "Screenings help identify potential concerns, but a diagnosis often requires further evaluation by specialists.",
      },
    ],
    link: "developmental-screening",

    points: [
      "Early Identification of Delays",
      "Tailored Support Plans",
      "Parental Involvement",
      "Regular Well-Child Screenings",
      "Observational Assessment",
    ],
    usps: [
      { uspName: "Early Intervention Support", uspPercentage: "92%" },
      { uspName: "Parent-Child Involvement", uspPercentage: "80%" },
      { uspName: "Comprehensive Observations", uspPercentage: "88%" },
      // Add more USPs as needed
    ],
  },
  {
    name: "Occupational Therapy for Children",
    image: image5,
    desc: "Our Occupational Therapy for children focuses on addressing developmental challenges associated with ADHD, Autism, and Intellectual Development Delay. Through tailored sessions and family involvement, we strive to enhance children's daily functioning and overall well-being.",
    subtitle:
      "Attention Deficit Hyperactivity Disorder (ADHD), Autism, Intellectual Development Delay",
    faqDesc:
      "Frequently Asked Questions about Occupational Therapy for Children",
    faqs: [
      {
        question:
          "What does an occupational therapy session involve for children?",
        answer:
          "Sessions may include activities that aim to improve fine motor skills, sensory integration, and daily life functions based on individual needs.",
      },
      {
        question:
          "How frequently should a child attend occupational therapy sessions?",
        answer:
          "The frequency depends on the child's needs and therapy goals, which will be discussed and planned with the therapist.",
      },
    ],
    points: [
      "Individualized Treatment Plans",
      "Controlled and Monitored Sessions",
      "Collaboration with Mental Health Professionals",
      "Continuous Monitoring of Side Effects",
      "Comprehensive Post-Treatment Support",
    ],
    usps: [
      { uspName: "Safety and Efficacy", uspPercentage: "95%" },
      {
        uspName: "Collaborative Approach with Professionals",
        uspPercentage: "88%",
      },
      { uspName: "Comprehensive Post-Treatment Support", uspPercentage: "90%" },
      // Add more USPs as needed
    ],
    link: "occupational-therapy",
  },
  {
    name: "Family Counselling",
    desc: "Our Family Counselling services provide supportive guidance for families facing diverse challenges. Addressing communication problems, conflicts, parenting issues, grief, and more, our aim is to enhance overall family dynamics through empathetic and effective counselling.",
    image: image7,
    subtitle: "Assistance for Family Issues and Dynamics",
    faqDesc: "Frequently Asked Questions about Family Counselling",
    faqs: [
      {
        question:
          "What types of issues can be addressed in family counselling?",
        answer:
          "Family counselling can address communication problems, conflicts, parenting issues, grief, and more, aiming to improve overall family dynamics.",
      },
      {
        question:
          "Can I attend family counselling without other family members?",
        answer:
          "While involving all family members is ideal, individual sessions may also be beneficial. The approach will depend on the specific situation.",
      },
      {
        question:
          "How many sessions are typically required for family counselling?",
        answer:
          "The number of sessions varies based on the issues and progress made. A treatment plan will be discussed with the counsellor.",
      },
    ],
    points: [
      "Empathetic Guidance for Families",
      "Tailored Solutions for Specific Challenges",
      "Flexible Session Formats",
      "Confidential and Non-Judgmental Environment",
      "Collaborative Approach with Family Members",
    ],
    usps: [
      { uspName: "Empathetic Guidance", uspPercentage: "95%" },
      { uspName: "Tailored Solutions", uspPercentage: "92%" },
      { uspName: "Collaborative Approach", uspPercentage: "90%" },
      // Add more USPs as needed
    ],
    link: "family-counselling",
  },

  {
    name: "Stress Management",
    desc: "Our Stress Management services offer a range of techniques and therapies to effectively manage and reduce stress levels. From mindfulness and relaxation exercises to time management strategies, we provide comprehensive support for individuals seeking to enhance their coping skills and promote mental well-being.",
    image: image8,
    subtitle: "Coping Strategies for Stress",
    faqDesc: "Frequently Asked Questions about Stress Management",
    faqs: [
      {
        question: "What are some effective stress management techniques?",
        answer:
          "Techniques like mindfulness, relaxation exercises, time management, and seeking social support can help manage stress effectively.",
      },
      {
        question: "How soon can I expect to see improvements in stress levels?",
        answer:
          "Results can vary, but practicing stress management techniques consistently often leads to gradual improvements in stress levels.",
      },
      {
        question: "Can stress management help with physical health issues?",
        answer:
          "Reducing stress can positively impact physical health by potentially lowering blood pressure, improving sleep, and boosting immunity.",
      },
    ],
    points: [
      "Personalized Stress Reduction Plans",
      "Mindfulness and Relaxation Techniques",
      "Holistic Approach to Mental Well-Being",
      "Long-Term Stress Prevention Strategies",
      "Expert Guidance from Certified Professionals",
    ],
    usps: [
      { uspName: "Personalized Stress Reduction", uspPercentage: "90%" },
      { uspName: "Holistic Well-Being Approach", uspPercentage: "88%" },
      { uspName: "Expert Guidance", uspPercentage: "92%" },
      // Add more USPs as needed
    ],
    link: "stress-management",
  },

  {
    name: "Therapy for Sexual Dysfunction",
    desc: "Our specialized Therapy for Sexual Dysfunction addresses various sexual health issues, including erectile dysfunction, low libido, performance anxiety, and intimacy concerns. Through personalized therapeutic approaches, we aim to help individuals and couples achieve positive changes in their sexual well-being.",
    image: image9,
    subtitle: "Support for Sexual Health Concerns",
    faqDesc: "Frequently Asked Questions about Therapy for Sexual Dysfunction",
    faqs: [
      {
        question: "What types of sexual health issues can therapy address?",
        answer:
          "Therapy can address issues like erectile dysfunction, low libido, performance anxiety, intimacy concerns, and more.",
      },
      {
        question: "Is therapy for sexual dysfunction effective for everyone?",
        answer:
          "Therapy effectiveness varies based on individual circumstances, but it often helps individuals and couples improve sexual well-being.",
      },
    ],
    points: [
      "Personalized Treatment Plans",
      "Safe and Confidential Environment",
      "Couples and Individual Counseling Options",
      "Comprehensive Assessment of Sexual Health",
      "Qualified and Experienced Therapists",
    ],
    usps: [
      { uspName: "Personalized Treatment Plans", uspPercentage: "90%" },
      { uspName: "Safe and Confidential Environment", uspPercentage: "92%" },
      { uspName: "Comprehensive Assessment", uspPercentage: "88%" },
      // Add more USPs as needed
    ],
    link: "sexual-dysfunction",
  },

  {
    name: "Pre-Marital Counselling",
    desc: "Our Pre-Marital Counselling services offer guidance and support for couples preparing for marriage. Covering topics such as communication, conflict resolution, financial planning, and building a strong foundation, we provide valuable insights and tools to enhance the prospects of a successful marriage.",
    image: image10,
    subtitle: "Preparing for a Successful Marriage",
    faqDesc: "Frequently Asked Questions about Pre-Marital Counselling",
    faqs: [
      {
        question: "What topics are usually covered in pre-marital counselling?",
        answer:
          "Topics may include communication, conflict resolution, financial planning, expectations, and building a strong foundation for marriage.",
      },
      {
        question: "Is pre-marital counselling necessary for every couple?",
        answer:
          "While not mandatory, pre-marital counselling can provide valuable insights and tools for a successful marriage.",
      },
      {
        question:
          "How many sessions are typically required for pre-marital counselling?",
        answer:
          "The number of sessions varies based on the couple's needs and the depth of topics to be covered.",
      },
    ],
    points: [
      "Comprehensive Marriage Preparation",
      "Tailored Sessions to Address Specific Concerns",
      "Expert Guidance from Marriage Counselors",
      "Interactive and Engaging Counseling Techniques",
      "Flexible Session Scheduling",
    ],
    usps: [
      { uspName: "Comprehensive Marriage Preparation", uspPercentage: "92%" },
      { uspName: "Expert Guidance", uspPercentage: "90%" },
      { uspName: "Flexible Session Scheduling", uspPercentage: "88%" },
      // Add more USPs as needed
    ],
    link: "pre-martial-counseling",
  },

  {
    name: "Anger Management",
    desc: "Our Anger Management services provide effective strategies and techniques to manage and control anger. From deep breathing exercises to cognitive restructuring, our approach focuses on fostering better communication and reducing conflicts in personal relationships.",
    image: image11,
    subtitle: "Effective Coping for Anger Issues",
    faqDesc: "Frequently Asked Questions about Anger Management",
    faqs: [
      {
        question: "What are common techniques used in anger management?",
        answer:
          "Techniques include deep breathing, cognitive restructuring, assertiveness training, and stress reduction methods.",
      },
      {
        question: "Can anger management help in personal relationships?",
        answer:
          "Yes, learning to manage anger can significantly improve relationships by reducing conflicts and fostering better communication.",
      },
      {
        question:
          "How long does it take to see results from anger management techniques?",
        answer:
          "Results can vary, but with consistent practice, individuals often notice improvements in managing anger within a few weeks to months.",
      },
    ],
    points: [
      "Practical Anger Management Techniques",
      "Individualized Coping Plans",
      "Application of Stress Reduction Methods",
      "Improvement in Personal Relationships",
      "Supportive Environment for Behavior Change",
    ],
    usps: [
      { uspName: "Practical Techniques", uspPercentage: "90%" },
      { uspName: "Individualized Coping Plans", uspPercentage: "88%" },
      { uspName: "Supportive Environment", uspPercentage: "92%" },
      // Add more USPs as needed
    ],
    link: "anger-management",
  },

  {
    name: "Grief Therapy",
    desc: "Our Grief Therapy services offer supportive therapy for individuals coping with grief and loss. Providing a safe space to express emotions, learn coping strategies, and navigate the grieving process, we aim to assist individuals in managing complicated or prolonged grief.",
    image: image12,
    subtitle: "Assistance in Grieving Process",
    faqDesc: "Frequently Asked Questions about Grief Therapy",
    faqs: [
      {
        question: "How can grief therapy help someone coping with loss?",
        answer:
          "Grief therapy provides a safe space to express emotions, learn coping strategies, and navigate the grieving process.",
      },
      {
        question: "When is the right time to seek grief therapy after a loss?",
        answer:
          "The timing varies for each individual, but seeking support when experiencing significant distress can be beneficial.",
      },
      {
        question: "Can grief therapy help in managing complicated grief?",
        answer:
          "Yes, therapy can assist individuals in navigating complicated or prolonged grief by providing support and guidance.",
      },
    ],
    points: [
      "Safe Space for Emotional Expression",
      "Coping Strategy Development",
      "Guidance in Navigating Grieving Process",
      "Customized Support Plans",
      "Compassionate and Understanding Therapists",
    ],
    usps: [
      { uspName: "Safe Emotional Space", uspPercentage: "92%" },
      { uspName: "Customized Support Plans", uspPercentage: "90%" },
      { uspName: "Compassionate Therapists", uspPercentage: "88%" },
    ],
    link: "grief-therapy",
  },

  {
    name: "Integrated Yoga Therapy",
    desc: "Our Integrated Yoga Therapy utilizes yoga as a therapeutic approach for mental health and insomnia. By combining physical postures, breathing exercises, and meditation, we offer a holistic approach to managing psychological issues. Yoga is adaptable to different age groups and fitness levels, making it accessible to many individuals.",
    image: image13,
    subtitle: "Yoga for Psychological Disorders and Insomnia",
    faqDesc: "Frequently Asked Questions about Integrated Yoga Therapy",
    faqs: [
      {
        question: "How can yoga help with psychological disorders?",
        answer:
          "Yoga combines physical postures, breathing exercises, and meditation, offering relaxation and stress relief, which can aid in managing psychological issues.",
      },
      {
        question: "Is yoga therapy suitable for all ages?",
        answer:
          "Yoga can be adapted to suit different age groups and fitness levels, making it accessible to many individuals.",
      },
      {
        question: "Can yoga therapy be beneficial for insomnia?",
        answer:
          "Yes, practicing yoga regularly can promote relaxation, reducing stress and anxiety that often contribute to insomnia.",
      },
    ],
    points: [
      "Holistic Approach to Mental Health",
      "Tailored Yoga Practices for Individuals",
      "Adaptable to Different Ages and Fitness Levels",
      "Promotion of Relaxation and Stress Relief",
      "Integration of Mind-Body Connection",
    ],
    usps: [
      { uspName: "Holistic Approach", uspPercentage: "95%" },
      { uspName: "Adaptable Practices", uspPercentage: "92%" },
      { uspName: "Mind-Body Connection", uspPercentage: "90%" },
    ],
    link: "yoga-therapy",
  },

  {
    name: "Inpatient De-Addiction Facility",
    desc: "Our Inpatient De-Addiction Facility provides comprehensive inpatient care for individuals dealing with addiction. With services including medical detoxification, counseling, therapy, and a supportive environment, we aim to guide individuals on their path to recovery.",
    image: image14,
    subtitle: "Professional Support for Recovery",
    faqDesc: "Frequently Asked Questions about Inpatient De-Addiction Facility",
    faqs: [
      {
        question:
          "What services are offered in the inpatient de-addiction facility?",
        answer:
          "The facility provides medical detoxification, counseling, therapy, and a supportive environment to aid in recovery.",
      },
      {
        question:
          "How long does the inpatient de-addiction program usually last?",
        answer:
          "The duration varies based on individual needs and the treatment plan determined by the healthcare team.",
      },
    ],
    points: [
      "Safe and Controlled Detoxification",
      "24/7 Professional Monitoring and Support",
      "Individualized Treatment Plans",
      "Counseling and Therapy Sessions",
      "Transition and Aftercare Planning",
    ],
    usps: [
      { uspName: "Safe Detoxification", uspPercentage: "92%" },
      { uspName: "24/7 Professional Support", uspPercentage: "90%" },
      { uspName: "Individualized Treatment", uspPercentage: "88%" },
    ],
    link: "deaddiction-facility",
  },

  {
    name: "Neuropsychological Assessment",
    desc: "Our Neuropsychological Assessment involves comprehensive evaluations to assess brain-behavior relationships. Through various tests and measures, we assess cognitive functions, memory, attention, and other brain-related abilities to provide a detailed understanding of individual strengths and challenges.",
    image: image15,
    subtitle: "Understanding Brain-Behavior Connections",
    faqDesc: "Frequently Asked Questions about Neuropsychological Assessment",
    faqs: [
      {
        question: "What does a neuropsychological assessment involve?",
        answer:
          "It includes various tests and measures assessing cognitive functions, memory, attention, and other brain-related abilities.",
      },
      {
        question:
          "How long does a neuropsychological assessment typically take?",
        answer:
          "The duration varies based on the battery of tests required, but it can range from a few hours to a full day.",
      },
    ],
    points: [
      "In-Depth Cognitive Function Evaluation",
      "Detailed Assessment of Memory and Attention",
      "Tailored Recommendations for Improvement",
      "Understanding Individual Strengths and Challenges",
      "Comprehensive Report with Actionable Insights",
    ],
    usps: [
      { uspName: "In-Depth Evaluation", uspPercentage: "95%" },
      { uspName: "Tailored Recommendations", uspPercentage: "92%" },
      { uspName: "Comprehensive Reporting", uspPercentage: "90%" },
      // Add more USPs as needed
    ],
    link: "neuropsychological-assessment",
  },

  {
    name: "Cognitive Behavioral Therapy (CBT)",
    desc: "Our Cognitive Behavioral Therapy (CBT) is an evidence-based therapy that focuses on changing negative patterns of thinking. Through practical strategies, individuals identify and modify negative thought patterns and behaviors to achieve positive changes in their emotional and mental well-being.",
    image: image16,
    subtitle: "Addressing Thoughts and Behaviors",
    faqDesc:
      "Frequently Asked Questions about Cognitive Behavioral Therapy (CBT)",
    faqs: [
      {
        question: "How does CBT work?",
        answer:
          "CBT helps individuals identify negative thought patterns and behaviors, then works on changing them through practical strategies.",
      },
      {
        question: "Is CBT effective for all mental health conditions?",
        answer:
          "CBT has shown effectiveness for various conditions like depression, anxiety, PTSD, OCD, and more, but outcomes can vary.",
      },
    ],
    points: [
      "Identification and Modification of Negative Thought Patterns",
      "Evidence-Based Approaches for Various Conditions",
      "Practical Strategies for Emotional Well-Being",
      "Goal-Oriented and Collaborative Therapy",
      "Effective Tools for Long-Term Positive Changes",
    ],
    usps: [
      { uspName: "Evidence-Based Approaches", uspPercentage: "95%" },
      { uspName: "Goal-Oriented Therapy", uspPercentage: "92%" },
      { uspName: "Effective Tools for Positive Changes", uspPercentage: "90%" },
    ],
    link: "cognitive-behavioral-therapy",
  },
  {
    name: "Ketamine Therapy for Depression",
    image: image4,
    subtitle: "Innovative Treatment for Depression",
    faqDesc: "Frequently Asked Questions about Ketamine Therapy",
    desc: "Our Ketamine Therapy offers innovative and effective treatment for depression. By leveraging the therapeutic benefits of ketamine, we aim to provide relief to individuals who have not responded to conventional treatments.",
    faqs: [
      {
        question: "What is the typical duration of a ketamine therapy session?",
        answer:
          "Sessions usually last around 40-60 minutes, but the duration may vary based on individual response and treatment protocol.",
      },
      {
        question: "Are there any potential side effects of ketamine therapy?",
        answer:
          "Potential side effects may include dizziness, nausea, and temporary changes in perception, which typically subside shortly after the session.",
      },
      {
        question:
          "How soon can I expect to notice improvements in my depression symptoms?",
        answer:
          "Responses vary among individuals. Some may notice improvements shortly after a few sessions, while others may take longer.",
      },
    ],
    points: [
      "Personalized Treatment Plans",
      "Rapid Symptom Relief",
      "Safe and Controlled Environment",
      "Experienced Healthcare Providers",
      "Holistic Mental Wellness Approach",
    ],
    usps: [
      { uspName: "Symptom Relief Efficiency", uspPercentage: "85%" },
      { uspName: "Customized Treatment Plans", uspPercentage: "90%" },
      { uspName: "Experienced Healthcare Providers", uspPercentage: "88%" },
    ],
    link: "ketamine-therapy",
  },
  {
    name: "Modified Electro Convulsive Therapy (ECT)",
    image: image6,
    subtitle: "Treatment for Severe Mental Health Conditions",
    faqDesc: "Frequently Asked Questions about Modified ECT",
    desc: "Our Modified ECT is a safe and effective treatment for severe mental health conditions. Administered by trained professionals in controlled environments, it is recommended for individuals who haven't responded to other treatments or require immediate intervention.",
    faqs: [
      {
        question: "Is ECT a safe treatment for mental health conditions?",
        answer:
          "When administered by trained professionals in controlled environments, ECT is considered safe and effective for certain conditions.",
      },
      {
        question: "What conditions are typically treated with modified ECT?",
        answer:
          "ECT is often used for severe depression, bipolar disorder, and certain cases of schizophrenia that haven't responded to other treatments.",
      },
      {
        question: "How many sessions of ECT are usually required?",
        answer:
          "The number of sessions varies based on the individual's response and the severity of the condition. A treatment plan will be discussed with your doctor.",
      },
    ],
    link: "modified-ect",
    points: [
      "Individualized Treatment Plans",
      "Controlled and Monitored Sessions",
      "Collaboration with Mental Health Professionals",
      "Continuous Monitoring of Side Effects",
      "Comprehensive Post-Treatment Support",
    ],
    usps: [
      { uspName: "Safety and Efficacy", uspPercentage: "95%" },
      {
        uspName: "Collaborative Approach with Professionals",
        uspPercentage: "88%",
      },
      { uspName: "Comprehensive Post-Treatment Support", uspPercentage: "90%" },
    ],
  },
];
