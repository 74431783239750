import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";
import { team } from "../../data/team";

class ContactUs extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner banner-lg contact-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Contact Us</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact Us
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/* <img className="pt-img1 animate-wave" src={animateWave} alt=""/>
							<img className="pt-img2 animate2" src={animate2} alt=""/>
							<img className="pt-img3 animate-rotate" src={animateRotate} alt=""/> */}
            </div>
          </div>

          <section className="">
            <div className="container">
              <div className="contact-wraper">
                <div className="row">
                  <div className="col-lg-6 mb-30">
                    <form
                      className="form-wraper contact-form ajax-form"
                      action="https://formsubmit.co/mindcarecliniccuddalore@gmail.com"
                      method="POST"
                    >
                      <div className="ajax-message"></div>
                      <div className="row">
                        {/* Name */}
                        <div className="form-group col-md-12">
                          <input
                            name="Name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Your Name"
                          />
                        </div>

                        {/* Email */}
                        <div className="form-group col-md-12">
                          <input
                            name="Email"
                            type="email"
                            // required
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>

                        {/* Phone Numbers */}
                        <div className="form-group col-md-12">
                          <input
                            name="Phone"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Phone Numbers"
                          />
                        </div>

                        {/* Select Doctors */}
                        {/* <div className="form-group col-md-12">
                          <select
                            className="form-select form-control"
                            name="Doctor Name"
                              onChange={(e) => {
                                setData((prev) => {
                                  return { ...prev, doctor: e.target.value };
                                });
                              }}
                          >
                            <option defaultValue>Select Doctor</option>
                            {team?.map((doctor, i) => (
                              <option defaultValue={doctor?.name} key={i}>
                                {doctor?.name}
                              </option>
                            ))}
                          </select>
                        </div> */}

                        {/* Select Message */}
                        <div className="form-group col-md-12">
                          <textarea
                            name="Message"
                            required
                            className="form-control"
                            placeholder="Type Message"
                          ></textarea>
                        </div>

                        {/* Submit */}
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            defaultValue="Submit"
                            className="btn w-100 btn-secondary btn-lg"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-lg-6 mb-30"
                    style={
                      typeof window != "undefined" && window.innerWidth < 600
                        ? { paddingRight: "0", paddingLeft: "0" }
                        : {}
                    }
                  >
                    <div
                      className="contact-info ovpr-dark"
                      style={{
                        backgroundImage: "url(" + pic1 + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="info-inner">
                        <h4 className="title mb-30">
                          Contact Us For Any Informations
                        </h4>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-map-alt"></i>Cuddalore Clinic
                          </h6>
                          <p>
                            Mind Care Clinic , ALC Campus (Opp to Dr. Renugadevi
                            Clinic) Near Arcot Hospital Cuddalore, Tamil Nadu-
                            607001
                          </p>
                        </div>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-map-alt"></i>Puducherry Clinic
                          </h6>
                          <p>
                            MIND CARE CLINIC , Rani Hospital No: 27, Vazhudavoor
                            Rd, Near Rajiv Gandhi Statue, Pettaiyanchathiram,
                            Aruthra Nagar, Puducherry, 605009
                          </p>
                        </div>
                        <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-id-badge"></i>Email &amp; Phone
                          </h6>
                          <Link to="#" className="text-white">
                            mindcarecliniccuddalore@gmail.com
                          </Link>
                          <p className="contact_us-number">
                            {" "}
                            <a href="tel:+91 9500423778">
                              {" "}
                              +91 9500423778{" "}
                            </a>{" "}
                            <span style={{ padding: "0 .5rem" }}>|</span>
                            <a href="tel:91 8072367642">
                              {" "}
                              +91 8072367642{" "}
                            </a>{" "}
                          </p>
                        </div>
                        {/* <div className="icon-box">
                          <h6 className="title">
                            <i className="ti-world"></i>Follow Us
                          </h6>
                          <ul className="social-media">
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://twitter.com/"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.linkedin.com/"
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.instagram.com/"
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature4">
                    <div className="icon-md feature-icon">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Contact Number</h5>
                      <p>+91 9500423778</p>
                      {/* <p>+002 3424 44 00</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature3">
                    <div className="icon-md feature-icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Email Address</h5>
                      <p>mindcarecliniccuddalore@gmail.com</p>
                      {/* <p>example@support.com</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature2">
                    <div className="icon-md feature-icon">
                      <img src={icon2} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Address</h5>
                      <p>
                        MindCare Clinic , ALC Campus (Opp to Dr. Renugadevi
                        Clinic) Near Arcot Hospital Cuddalore, Tamil Nadu-
                        607001
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContactUs;
