import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import servicesPic1 from "../../images/services/pic1.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import { services } from "../../data/services";

const ServiceDetail = () => {
  const params = useParams();
  const service = services.find((service) => service.link === params.id);

  console.log(service);

  return (
    <>
      <div className="page-content bg-white ">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1
                  style={{
                    marginTop: "40px",
                    fontSize: "48px",
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "30px",
                  }}
                >
                  {service.name}
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {service.name}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-30">
                <div className="ttr-media mb-30">
                  <img
                    src={service?.image}
                    className="rounded"
                    alt=""
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15" style={{ lineHeight: "1.4" }}>
                      {service?.subtitle}
                    </h2>
                    <p className="mb-0">{service?.desc}</p>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-6 mb-30">
                      <ul className="list-check-squer mb-0">
                        {service?.points?.map((data) => (
                          <li style={{ fontFamily: "inherit" }}>{data}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-6 mb-30">
                      {service?.usps?.map((data, i) => (
                        <div className="skillbar-box mb-30" key={i}>
                          <h6 className="title">{data.uspName}</h6>
                          <div className="skillbar appear">
                            <p
                              className="skillbar-bar"
                              style={{ width: data.uspPercentage }}
                            ></p>
                            <span className="skill-bar-percent">
                              {data.uspPercentage}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">Popular Questions</h4>
                    <p className="mb-0">{service?.faqDesc}</p>
                  </div>
                  <Accordion
                    defaultActiveKey="0"
                    className="accordion ttr-accordion1"
                  >
                    {service?.faqs?.map((faq, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{faq?.question}</Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">{faq.answer}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <aside className="sticky-top pb-1">
                  <div className="widget">
                    <div className="service-menu">
                      <ul
                        style={{
                          maxHeight: "600px",
                          overflowY: "scroll",
                          paddingRight: "2px",
                        }}
                      >
                        {services.map((singleService) => (
                          <li
                            className={
                              singleService.link === service.link && "active"
                            }
                          >
                            <Link to={`/services/${singleService?.link}`}>
                              <span>{singleService?.name}</span>
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {service?.files?.length > 0 && (
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        {service.files.map((file) => (
                          <Link to={file?.link} className="download-link">
                            <img src={pdf} alt="" />
                            <h5 className="title">{file?.name}</h5>
                            <span>Download</span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </aside>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServiceDetail;
