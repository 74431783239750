import teamMember1 from "../images/gallery/clinicStaffAndInterior/teamParth.jpeg";
import teamMember2 from "../images/gallery/clinicStaffAndInterior/teamCN.JPG";
import teamMember3 from "../images/gallery/clinicStaffAndInterior/teamEN.JPG";
import teamMember4 from "../images/gallery/clinicStaffAndInterior/teamBN.JPG";
import teamMember5 from "../images/gallery/clinicStaffAndInterior/teamDN.JPG";
import teamMember6 from "../images/gallery/clinicStaffAndInterior/teamFN.jpeg";
import teamMember7 from "../images/gallery/clinicStaffAndInterior/teamGN.jpeg";

export const team = [
  {
    name: "Dr. Partheeban",
    image: teamMember1,
    specialization: "Neuro Psychiatrist, Deaddiction Specialist and Sexologist",
    description:
      "Dr. Partheeban, MD(NIMHANS), PDF (Sexual Medicine), ACCMAD (Addiction Medicine), FIPS has completed his post-graduation in Neuro-Psychiatry from Asia’s No.1 premier Psychiatric hospital NIMHANS (National Institute of Mental Health and Neuro Sciences) Bangalore, India.",
    professionalAchievements: [
      "Received Best Counsellor (Psychotherapy) & Service award among all postgraduates at NIMHANS.",
      "Received Outstanding Medical Service Award from Junior Club International, Pondicherry Metro in October 2023.",
      "Completed Basic and Advanced Course in Management of Addiction Medicine (ACCMAD) from India’s largest Centre for Addiction Medicine, NIMHANS, Bangalore.",
      "Completed post-doctoral Fellowship on Sexual Medicine from JSS University, Mysore, India.",
      "Has 15 years of experience in the field of mental health.",
      "Received Best Young Researcher Grant Award from European Psychiatric Society, Florence, Italy.",
      "Worked at India’s largest De-addiction Centre, NIMHANS.",
      "Formerly worked as Associate Professor at Dept of Psychiatry, Mahatma Gandhi Medical College, Puducherry, India.",
      "Currently managing director of MIND CARE CLINIC, Cuddalore & Pondicherry.",
      "De-addiction specialist at Barath Matha de-addiction center, Ariyankuppam, Puducherry.",
      "Specializes in Addiction Medicine, Child Psychiatry, and Sexual Medicine.",
      "Experienced in delivering Cognitive Behaviour Therapy (CBT) for depression and anxiety disorders, Exposure Response Prevention Therapy for OCD, JPMR for Insomnia, EMDR for Post-Traumatic Disorder (PTSD), Grief Therapy, Motivational Enhancement Therapy for addiction-related problems, and Psychological Therapy for sexual problems.",
      "Conducted more than 50 community awareness programs on Impact of Substance Use and Addiction Management for school, college students, NGOs, self-help groups, and high-risk children like orphanages organized by the Social Welfare Department, Puducherry.",
      "Resource person for many state and central government projects on National Action Plan on Drug Demand Reduction (NAPDDR) & Nasha Mukt Bharat Abhiyaan Scheme.",
      "Conducted many community awareness programs on stress management in life, Facing public exams with positivity, suicide prevention, Handling phone addiction, psychological ways of learning, Identifying and managing children with academic difficulties for school, college students, and teachers.",
      "Conducted project “G Man(Gentleman)” in association with Suryan FM in schools around Puducherry on Healthy and respectful ways of approaching the opposite gender.",
      "Resource person on “Safe Sex Education for Special Children” organized by the National Institute for Empowerment of Persons with Multiple Disabilities.",
      "Panel expert for student mental health welfare at Pondicherry University.",
    ],
  },
  {
    name: "Mr. Dhinesh Kumar",
    image: teamMember2,
    specialization: "Clinical Psychologist",
    description:
      "Mr. Dhinesh Kumar is a licensed Clinical Psychologist, Child Psychologist, Counselling Psychologist and Psychotherapist in Cuddalore and has 5yrs experience in the field of mental health and psychology. He completed his Master in Clinical Post Graduation Diploma in Guidance Counselling Psychology from Annamalai University, Chidambaram.  He completed his M.Phil in Clinical Psychology from SRM University, Chennai, 2022. He is a member of licensed Rehabilitation council of India for Clinical Psychologist Reg NO A89322.",
  },
  {
    name: "Mr. Dhanush",
    specialization: "Yoga Therapist",
    image: teamMember3,
    description:
      "Mr. Dhanush, our esteemed Yoga Therapist at Mindcare. He is awarded with Yoga Cheemal Award for the best yoga teacher. With a profound understanding of yoga and its therapeutic benefits, he plays a pivotal role in promoting mental well-being. Mr. Dhanush brings a wealth of experience to Mindcare, utilizing yoga as a powerful tool for holistic healing. His dedication to fostering inner peace and balance aligns seamlessly with Mindcare's commitment to comprehensive mental health services.",
  },
  {
    name: "Mrs. Aruna Kumari.G",
    specialization: "Clinical Psychologist",
    // specialization: "Yoga Therapist",
    image: teamMember4,
    description:
      "Ms. Aruna Kumari.G is an RCI Licensed Clinical Psychologist (CRR.No: A83142). She completed her M.Phil. in Clinical Psychology from the National Institute for Empowerment of Persons with Multiple Disabilities (NIEPMD), a renowned Central Government Institution dedicated to working with individuals with disabilities. Specializing in child psychology and personality disorders, she brings 7 years of experience in the field of Mental Health, including 2 years in De-addiction. Ms. Kumari has further enriched her expertise through certified training in Behavior Therapy, Psychotherapy, and counseling. Previously, she served as a Clinical Psychologist at Vazhikatti Mental Health and Research Institute, Coimbatore. Currently, she practices at Mind Care Clinic, Cuddalore, demonstrating proficiency in providing Behavior Modification for children, Behavior therapy, Dialectical Behavior Therapy, Cognitive Behavioral Therapy, De-addiction counseling, Couple Therapy, and Family Therapy. Her skills extend to psychometric assessments, addressing Childhood Disorders, and managing mental illnesses. Notably, she serves as one of the Panel of Experts for Student Welfare at Pondicherry University.",
  },
  {
    name: "Mr. S. Artchoudane",
    // specialization: "B.Ed (Spl) | M Sc (YT) | ID, Ph.D",
    specialization: "Yoga Therapist & Nutritionist",
    image: teamMember5,
    description:
      "He completed his i) post graduates in Yoga therapy, Varmam therapy and Herbal medicines, ii) Special Education – Intellectual  disability, and iii) Doctorate in Yoga therapy.  Yoga and meditation have been a part of his life for more than 20 years. He is currently working as a Licensed Yoga Therapist for more than 10 years He is a professional member and life member of Indian Yoga Association, New Delhi, India.  He received a awards like “Best Yoga Teacher”, “Yuva Bharathi”, and “Young Achiever”",
  },
  {
    name: "Mr. Karthik",
    specialization: "Special Educator",
    image: teamMember6,
    description:
      "Mr. Karthik, who is our dedicated Special Educator cum Teacher at Mindcare for special children and children with academic difficulties. Employing personalized, evidence-based strategies, Mr. Karthik fosters a love for learning in a nurturing environment. His commitment to empowering minds aligns seamlessly with Mindcare's mission.",
  },
  {
    name: "Dr. P. Punniya Moorthy",
    specialization: "Occupational Therapist",
    image: teamMember7,
    description:
      "Dr. P. Punniya moorthy completed Bachelor, Master and PhD in Occupational therapy. He has more than 10 years of working experience. He received Best principal contribution award from Sri Ramachandhara medical College at Chennai. He received Principal Contribution Award from All India Occupational therapists Association (AIOTA) conference held at saveetha college of occupational therapy.His Area of interest is Paediatric development intervention He conducted Hand writing skills training at National Institute for Empowerment of Persons with Multiple Disabilities (NIPMED), Chennai.He is the Co- Author for the book: Autism ஒரு தெளிவு (book) He is the TN- AIOTA- Zonal coordinator in All India Occupational therapists Association, Tamilnadu chapter He is treasurer for in All India Occupational therapists Association, Puducherry chapter .",
  },
];
