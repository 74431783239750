import React, { Component, useState } from "react";

// Import Images
import teamMember1 from "../../images/gallery/clinicStaffAndInterior/teamAN.JPG";
import teamMember2 from "../../images/gallery/clinicStaffAndInterior/teamCN.JPG";
import teamMember3 from "../../images/gallery/clinicStaffAndInterior/teamEN.JPG";
import teamMember4 from "../../images/gallery/clinicStaffAndInterior/teamBN.JPG";
import teamMember5 from "../../images/gallery/clinicStaffAndInterior/teamDN.JPG";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-dots-orange.png";
import ptImg3 from "../../images/shap/line-circle-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";
import ptImg5 from "../../images/shap/circle-dots.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { team } from "../../data/team";

function TeamSection() {
  const [show, setShow] = useState(false);
  const [teamMember, setTeamMember] = useState({
    image: "",
    name: "",
    role: "",
    description: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center ">
            <div className="team-media">
              <img
                src={teamMember.image}
                className="rounded-1"
                alt=""
                style={{ maxHeight: "200px" }}
              />
            </div>
            <div className="team-info">
              <div className="team-info-comntent">
                <h4 className="title text-center my-3">{teamMember.name}</h4>
                <span className="text-secondary">{teamMember.role}</span>
                <p>{teamMember?.description}</p>
                <ul>
                  {teamMember?.professionalAchievements?.map((item) => (
                    <li style={{ listStyle: "inside" }}>{item}</li>
                  ))}
                </ul>
              </div>
              <ul className="social-media mt-3">
                {teamMember?.socialMedia?.map((social, index) => (
                  <li key={index}>
                    <a rel="noreferrer" target="_blank" href={social.link}>
                      <i
                        className={`fab fa-${social.platform.toLowerCase()}`}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section-area section-sp3 team-wraper">
        <div className="container">
          <div className="heading-bx text-center">
            <h6 className="fw8 text-secondary">Our Doctor</h6>
            <h2 className="title">Meet Best Doctors</h2>
          </div>
          <div className="row justify-content-center">
            {team.map((teamMember, index) => (
              <div key={index} className="col-lg-4 col-sm-6 mb-30">
                <div className="team-member">
                  <div className="team-media">
                    <img src={teamMember.image} alt={teamMember.name} />
                  </div>
                  <div className="team-info">
                    <div className="team-info-content">
                      <h4 className="title">{teamMember.name}</h4>
                      <span className="text-secondary">
                        {teamMember.specialization}
                      </span>
                    </div>

                    <Button
                      variant="primary"
                      onClick={() => {
                        handleShow();
                        setTeamMember(teamMember);
                      }}
                      className="mt-2"
                    >
                      Know More
                    </Button>
                    {/* <ul className="social-media mt-3">
                        {teamMember.socialMedia.map((social, index) => (
                          <li key={index}>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={social.link}
                            >
                              <i
                                className={`fab fa-${social.platform.toLowerCase()}`}
                              />
                            </a>
                          </li>
                        ))}
                      </ul> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img className="pt-img1 animate1" src={ptImg1} alt="" />
        <img className="pt-img2 animate2" src={ptImg2} alt="" />
        <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
        <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
      </section>
    </>
  );
}

export default TeamSection;
