import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png";
import testPic1 from "../../images/gallery/clinicStaffAndInterior/teamBN.JPG";
import testPic2 from "../../images/gallery/clinicStaffAndInterior/teamCN.JPG";
import testPic3 from "../../images/gallery/clinicStaffAndInterior/teamDN.JPG";
import testPic4 from "../../images/gallery/clinicStaffAndInterior/teamEN.JPG";
import testPic5 from "../../images/gallery/clinicStaffAndInterior/teamFN.jpeg";
import testPic6 from "../../images/gallery/clinicStaffAndInterior/teamGN.jpeg";
import testPic7 from "../../images/gallery/clinicStaffAndInterior/teamAN.JPG";

import plusOrange from "../../images/shap/plus-orange.png";
import squareBlue from "../../images/shap/square-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import circleOrange2 from "../../images/shap/circle-orange-2.png";

function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data = [
    {
      name: "Ramakrishnan A.M.",
      title: "patient",
      description:
        "When one of my family members was going through a tough phase, we consulted Dr Partheeban. He is a very humble person who treats his patients with utmost care and affection. He patiently listens to everything and prescribes only the amount of medicines which is actually necessary. We could see so much improvement in a short time. I am very grateful for the wonderful consultation and treatment provided by Dr Partheeban.",
    },
    {
      name: "K. Bala",
      title: "patient",
      description:
        "I consulted Dr Parthibhan at the Mind Care Clinic for my elderly mother. He did a thorough examination of all the symptoms, problems and antecedents. We've seen an immediate improvement in the initial treatment itself. Since then, follow-up and medication adjustments are helping our mother's recovery. Thank you Dr.Parthibhan for your holistic review, rapid follow-on treatment.",
    },
    {
      name: "Senthamil selvan",
      title: "patient",
      description:
        "Wonderful doctor.  He is up to date and provides support and solutions for and mind related issues.  Phenomenal.",
    },
    {
      name: "Lorenceia Christ",
      title: "patient",
      description:
        "A really trustable doctor. Treatment was very smooth and careful. Definitely recommend for people struggling to take care of mental health.",
    },
    {
      name: "K. Rajamanikandan Kuppusamy",
      title: "patient",
      description:
        "From 2019 onwards we have been in touch with doctor and because of his able guidance issue's have been gradually reduced and doctor has also kind enough to support by reducing dose of medicine too.",
    },
    {
      name: "Siva Shakthi A",
      tite: "patient",
      description:
        "It has been a great relief being under his treatment. He is good at understanding the issues and maintains confidentiality. Been under his treatment for almost 3 years now and I am definitely improving. His treatment comes with enough motivation which makes it easier to go through the medication phase.",
    },
    {
      name: "Guru Ravi",
      title: "patient",
      description:
        "I consulted him thrice. Very approachable person. He spends lot of time in understanding my problem and strengthened me during my difficult times. He counsell more than providing pills. He provides his contact number for any emergency. I strongly recommend others who needs good support.",
    },
    {
      name: "Margaret Rani",
      title: "patient",
      description:
        "Dr. Parthiban is an excellent doctor. He spends 45 minutes to even more than an hour for the patients. I know him well. His primary aim is curing the patient not making money. He has empathetic attitude. If you want good counseling and speedy recovery the best doctor in Pondy is Dr. Parthiban.",
    },
    {
      name: "Padman Naban",
      title: "patient",
      description:
        "Dr partheeban neuro psychiatrist. He is a very kind  and gentle towards patients. I took my wife to many psyhiatrists in cuddalore  pondicherry  and tanjavur but at last we found  DR partheenan He patiently listens to the patients and gives correct treatment. Thanks to the treatement my wife is in a normal condition. Thanks a lot",
    },
  ];
  return (
    <>
      <section className="section-area section-sp3 testimonial-wraper">
        <div className="container">
          <div className="heading-bx text-center">
            <h6 className="fw8 text-secondary">Patient Praise</h6>
            <h2 className="title m-b0">Hear Stories of Transformation</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              <div className="thumb-wraper">
                <img className="bg-img" src={testShape} alt="" />
                <ul>
                  <li data-member="1">
                    <Link to="#">
                      <img src={testPic1} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="2">
                    <Link to="#">
                      <img src={testPic2} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="3">
                    <Link to="#">
                      <img src={testPic3} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="4">
                    <Link to="#">
                      <img src={testPic4} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="5">
                    <Link to="#">
                      <img src={testPic5} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="6">
                    <Link to="#">
                      <img src={testPic6} alt="" loading="lazy" />
                    </Link>
                  </li>
                  <li data-member="7" className="partiban">
                    <Link to="#">
                      <img src={testPic7} alt="" loading="lazy" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <Slider {...settings} className="testimonial-slide">
                {data.map((item) => (
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">
                        <p>{item?.description}</p>
                      </div>
                      <div className="client-info">
                        <h5 className="name">{item?.name}</h5>
                        <p>{item?.title}</p>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate1" src={plusOrange} alt="" />
        <img className="pt-img2 animate2" src={squareBlue} alt="" />
        <img className="pt-img3 animate3" src={circleDots} alt="" />
        <img className="pt-img4 animate4" src={circleOrange2} alt="" />
      </section>
    </>
  );
}

export default TestimonialSection;
