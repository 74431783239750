import React, { Component } from "react";

// Import Images
// import galleryPic1 from "../../images/gallery/pic1.jpg";
// import galleryPic2 from "../../images/gallery/pic2.jpg";
// import galleryPic3 from "../../images/gallery/pic3.jpg";
// import galleryPic4 from "../../images/gallery/pic4.jpg";
// import galleryPic5 from "../../images/gallery/pic5.jpg";
// import galleryPic6 from "../../images/gallery/pic6.jpg";

import galleryPic1 from "../../images/gallery/clinicStaffAndInterior/teamA1White.JPG";
import galleryPic2 from "../../images/gallery/clinicStaffAndInterior/teamMembers.JPG";
import galleryPic3 from "../../images/gallery/clinicStaffAndInterior/teamB1White.JPG";
import galleryPic4 from "../../images/gallery/clinicStaffAndInterior/teamC1White.JPG";
import galleryPic5 from "../../images/gallery/clinicStaffAndInterior/teamD1.JPG";
import galleryPic6 from "../../images/gallery/clinicStaffAndInterior/teamC1White.JPG";
import galleryPic7 from "../../images/gallery/clinicStaffAndInterior/teamA1.JPG";
import galleryPic8 from "../../images/gallery/clinicStaffAndInterior/CLINIC 6.jpeg";
import galleryPic9 from "../../images/gallery/clinicStaffAndInterior/CLINIC5.JPG";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 100,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  maxHeight:'90vh',
  padding:'0px',
  boxShadow: 24,
  maxWidth:'90vw',
  p: 0,
};
// import galleryPic1 from "../../images/gallery/clinicStaffAndInterior/clinic2.JPG";
// import galleryPic2 from "../../images/gallery/clinicStaffAndInterior/CLINIC 3.JPG";
// import galleryPic3 from "../../images/gallery/clinicStaffAndInterior/CLINIC 4.JPG";
// import galleryPic4 from "../../images/gallery/clinicStaffAndInterior/CLINIC 5.JPG";

const WidgetGallery = ({images, page, pageSize}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className="widget widget_gallery gallery-grid-3">
        {/* <h4 className="widget-title">Our Gallery</h4> */}

        <ul className="magnific-image">
          {images?.map((image, index) => (index >= ((page - 1) * pageSize) && index <= ((page) * pageSize)-1 && <li
          style={{cursor:'pointer'}}
          onClick={()=>{
            setOpen(true)
            setSelectedImage(image)
          }}
          >
            <img src={image} alt="" />
          </li>)
          )}
      </ul>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={selectedImage} alt="" style={{maxHeight:'90vh'}}/>
        </Box>
      </Modal>
    </div >
    </>
  );
}

export default WidgetGallery;
