import React, { Component } from "react";

class LatestNewsSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 service-wraper2">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">8+</h2>
                  <h5 className="ttr-title">Specialists</h5>
                  <p>
                    A team of 8+ specialists, bringing over a decade of
                    expertise in mental health care.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">1000+</h2>
                  <h5 className="ttr-title"> Success Stories</h5>
                  <p>
                    Over 1000 success stories, a testament to our commitment to
                    empowering lives.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">4+</h2>
                  <h5 className="ttr-title">Service Locations</h5>
                  <p>
                    Serving communities in 4 locations and continually expanding
                    to reach more individuals in need.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">5+</h2>
                  <h5 className="ttr-title">Therapeutic Approaches</h5>
                  <p>Each tailored to meet the unique needs of our clients.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LatestNewsSection;
