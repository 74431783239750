import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import blog1_main from "../../images/blog/blog-1/blog1-main.jpg";
import blog1_1 from "../../images/blog/blog-1/blog1-1.jpg";
import blog1_2 from "../../images/blog/blog-1/blog1-2.jpg";

import blog2_main from "../../images/blog/blog-2/blog2-main.jpg";
import blog2_1 from "../../images/blog/blog-2/blog2-1.jpg";
import blog2_2 from "../../images/blog/blog-2/blog2-2.jpg";

import blog3_main from "../../images/blog/blog-3/blog3-main.jpg";
import blog3_1 from "../../images/blog/blog-3/blog3-1.jpg";
import blog3_2 from "../../images/blog/blog-3/blog3-2.jpg";

import blog4_main from "../../images/blog/blog-4/blog4-main.jpg";
import blog4_1 from "../../images/blog/blog-4/blog4-1.jpg";
import blog4_2 from "../../images/blog/blog-4/blog4-2.jpg";

import blog5_main from "../../images/blog/blog-5/blog5-main.jpg";
import blog5_1 from "../../images/blog/blog-5/blog5-1.jpg";
import blog5_2 from "../../images/blog/blog-5/blog5-2.jpg";

import blog6_main from "../../images/blog/blog-6/blog6-main.jpg";
import blog6_1 from "../../images/blog/blog-6/blog6-1.jpg";
import blog6_2 from "../../images/blog/blog-6/blog6-2.jpg";

function BlogGrid() {
  // Blog Content
  const blogPost = [
    {
      thumb: blog2_main,
      title: "Unlocking Serenity: The Power of Mindful Meditation",
      p1: "Discover the profound impact of mindful meditation on mental well-being. Explore practical techniques and real-life stories showcasing the transformative journey to inner peace.",
      p2: "Embark on a mindfulness adventure with our expert tips and guided meditation exercises. Learn how incorporating mindfulness into your daily routine can enhance overall mental resilience.",
      image1: blog2_1,
      image2: blog2_2,
      p3: "Join us as we delve into the science behind mindfulness and its positive effects on stress reduction. Explore a variety of mindfulness practices suitable for beginners and experienced practitioners alike.",
      p4: "At Mindcare, we believe in empowering individuals to cultivate a calmer mind. Gain insights from our licensed therapists and witness the profound impact of mindfulness in promoting a harmonious life.",
      p5: "Embark on a journey of self-discovery and unlock the door to serenity through the art of mindful meditation with Mindcare.",
      slug: titleToSlug("Unlocking Serenity: The Power of Mindful Meditation"),
    },
    {
      thumb: blog3_main,
      title: "Breaking the Stigma: Nurturing Mental Health in the Workplace",
      p1: "Explore the importance of prioritizing mental health in the workplace. Gain valuable insights into fostering a supportive and stigma-free environment for employees' mental well-being.",
      p2: "At Mindcare, we delve into the challenges professionals face and offer practical solutions. Discover effective strategies for stress management and maintaining a healthy work-life balance.",
      image1: blog3_1,
      image2: blog3_2,
      p3: "Learn from real-world case studies highlighting the positive impact of mental health initiatives in corporate settings. Our blog provides actionable steps for employers and employees alike to create a culture of well-being.",
      p4: "Join us in breaking the stigma surrounding mental health at work. At Mindcare, we advocate for a workplace that nurtures mental health, empowering individuals to thrive both professionally and personally.",
      p5: "Together, let's transform the narrative and foster a workplace where mental health is prioritized and celebrated.",
      slug: titleToSlug(
        "Breaking the Stigma: Nurturing Mental Health in the Workplace"
      ),
    },
    {
      thumb: blog4_main,
      title: "The Journey to Self-Discovery: Navigating Personal Growth",
      p1: "Embark on a profound journey of self-discovery with Mindcare. Explore the intricacies of personal growth and gain valuable insights into navigating life's challenges with resilience.",
      p2: "Our team of experts shares practical tips on embracing change, building self-confidence, and fostering positive relationships. Discover transformative stories of individuals who found strength in their journey.",
      image1: blog4_1,
      image2: blog4_2,
      p3: "Dive into the world of self-reflection and mindfulness. Our blog provides resources for individuals seeking to enhance their emotional intelligence and cultivate a deeper understanding of themselves.",
      p4: "At Mindcare, we believe that personal growth is a continuous process. Join us in exploring the pathways to self-discovery and uncover the limitless potential within you.",
      p5: "Navigate the journey to self-realization with Mindcare's guidance, where every step forward is a step towards unlocking your true self.",
      slug: titleToSlug(
        "The Journey to Self-Discovery: Navigating Personal Growth"
      ),
    },
    {
      thumb: blog5_main,
      title: "Building Resilience: Overcoming Life's Toughest Challenges",
      p1: "Discover the art of building resilience in the face of adversity. Mindcare explores effective strategies for navigating life's toughest challenges and emerging stronger than ever.",
      p2: "Our experts share insights on stress management, coping mechanisms, and fostering mental fortitude. Learn from real-life stories of individuals who triumphed over difficulties with resilience.",
      image1: blog5_1,
      image2: blog5_2,
      p3: "Explore practical exercises to enhance emotional resilience and adaptability. Our blog provides a toolkit for individuals seeking to cultivate resilience as a key aspect of their mental well-being.",
      p4: "At Mindcare, we believe that resilience is a skill that can be developed. Join us on the journey to building inner strength and conquering life's challenges with unwavering resilience.",
      p5: "Navigate life's complexities with resilience as your compass, guided by Mindcare's expertise and commitment to empowering individuals through every twist and turn.",
      slug: titleToSlug(
        "Building Resilience: Overcoming Life's Toughest Challenges"
      ),
    },
    {
      thumb: blog6_main,
      title: "Mindful Parenting: Nurturing Children's Mental Well-Being",
      p1: "Explore the world of mindful parenting with Mindcare. Gain valuable insights into fostering children's mental well-being and creating a nurturing environment for their growth.",
      p2: "Our experts share tips on effective communication, emotional intelligence, and supporting children's mental health. Discover practical approaches to building a strong parent-child bond.",
      image1: blog6_1,
      image2: blog6_2,
      p3: "Delve into the challenges of modern parenting and explore evidence-based strategies. Our blog provides resources for parents seeking to navigate the complexities of raising emotionally resilient children.",
      p4: "At Mindcare, we understand the importance of a healthy parent-child relationship. Join us on the journey of mindful parenting and empower your children to thrive emotionally and mentally.",
      p5: "Create a lasting impact on your child's mental well-being with Mindcare's insights and guidance, as we navigate the path of mindful parenting together.",
      slug: titleToSlug(
        "Mindful Parenting: Nurturing Children's Mental Well-Being"
      ),
    },
    {
      thumb: blog1_main,
      title: "Navigating the Waves: Understanding Anxiety",
      p1: "Anxiety is a common mental health challenge that many individuals face. At Mindcare, we believe in shedding light on this issue to foster understanding and support. Anxiety can manifest in various ways, impacting both mental and physical well-being.",
      p2: "Understanding the roots of anxiety is crucial for effective management. It often stems from a combination of genetic, environmental, and psychological factors. Our team at Mindcare is dedicated to providing insights and coping strategies to empower individuals on their journey to mental wellness.",
      image1: blog1_1,
      image2: blog1_2,
      p3: "One effective approach to managing anxiety is through mindfulness practices. These techniques, such as deep breathing exercises and meditation, can help individuals gain control over their thoughts and emotions. Our blog explores practical tips and exercises that anyone can incorporate into their daily routine.",
      p4: "Additionally, our licensed psychologists share real-life success stories, illustrating how individuals have overcome anxiety with the support of our comprehensive mental health services. At Mindcare, we emphasize the importance of a personalized and holistic approach, recognizing that each individual's journey is unique.",
      p5: "Join us on this exploration of anxiety, its nuances, and the transformative power of mental health care. At Mindcare, we are committed to providing valuable resources and fostering a supportive community for those navigating the challenges of anxiety.",
      date: "25 July 2023",
      slug: titleToSlug("Navigating the Waves: Understanding Anxiety"),
    },
  ];

  function titleToSlug(blogTitle) {
    // Remove leading and trailing whitespaces
    let trimmedTitle = blogTitle.trim();

    // Replace spaces with hyphens and convert to lowercase
    let slug = trimmedTitle.replace(/ /g, "-").toLowerCase();

    return slug;
  }

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Blogs</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blogs
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              {blogPost.map((blogPost, index) => (
                <div key={index} className="col-xl-4 col-md-6">
                  <div
                    className="blog-card mb-30"
                    style={
                      typeof window != "undefined" && window.innerWidth > 769
                        ? { height: "550px" }
                        : { height: "auto" }
                    }
                  >
                    <div className="post-media">
                      <Link to={`/blog-details/${blogPost.slug}`}>
                        <img src={blogPost.thumb} alt="" />
                      </Link>
                    </div>
                    <div className="post-info">
                      <h4 className="post-title">
                        <Link to={`/blog-details/${blogPost.slug}`}>
                          {blogPost.title}
                        </Link>
                      </h4>
                      <Link
                        to={`/blog-details/${blogPost.slug}`}
                        className="btn btn-outline-primary btn-sm"
                      >
                        Read More{" "}
                        <i className="btn-icon-bx fas fa-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BlogGrid;
