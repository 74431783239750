import React, { Component, useState } from "react";

import { team } from "../../data/team";

function AppointmentForm() {
  //   const [data, setData] = useState({
  //     doctor: "",
  //     name: "",
  //     number: null,
  //     date: "",
  //   });

  return (
    <>
      <div className="appointment-form form-wraper">
        <h3 className="title">Book Appointment</h3>
        <form
          action="https://formsubmit.co/mindcarecliniccuddalore@gmail.com"
          method="POST"
        >
          {/* <div className="form-group">
            <select
              className="form-select form-control"
              name="Doctor Name"
                onChange={(e) => {
                  setData((prev) => {
                    return { ...prev, doctor: e.target.value };
                  });
                }}
            >
              <option defaultValue>Select Doctor</option>
              {team?.map((doctor, i) => (
                <option defaultValue={doctor?.name} key={i}>
                  {doctor?.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              className="form-control"
              // placeholder="Your Name"
              name="Name"
              //   onChange={(e) => {
              //     setData((prev) => {
              //       return { ...prev, name: e.target.value };
              //     });
              //   }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Your Contact Number</label>

            <input
              type="number"
              className="form-control"
              name="Phone Number"
              // placeholder="Phone Numbers"
              //   onChange={(e) => {
              //     setData((prev) => {
              //       return { ...prev, number: e.target.value };
              //     });
              //   }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Preferred Date</label>
            <input
              type="date"
              name="Date"
              className="form-control datetimeinput"
              //   onChange={(e) => {
              //     setData((prev) => {
              //       return { ...prev, date: e.target.value };
              //     });
              //   }}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-lg">
            Book Now
          </button>
        </form>
      </div>
    </>
  );
}

export default AppointmentForm;
