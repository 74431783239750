import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import Logo from "../../images/logo.png";
import footerBg from "../../images/background/footer.jpg";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import twitter from "../../images/social/twitter.png";
import instagram from "../../images/social/instagram.png";
import linkedin from "../../images/social/linkedin.png";
import { services } from "../../data/services";

class aboutSection extends Component {
  render() {
    return (
      <>
        <footer
          className="footer"
          style={{ backgroundImage: "url(" + footerBg + ")" }}
        >
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_info">
                    <div
                      className="footer-logo"
                      style={{ fontSize: "28px", fontWeight: "700" }}
                    >
                      {/* <Link to="/">
                        <img src={Logo} alt="" />
                      </Link> */}
                      {/* <Link to="/">MindCare</Link> */}
                    </div>
                    <div className="ft-contact">
                      <h4 className="number"> Cuddalore Clinic</h4>
                      <p>
                        Mind Care Clinic, ALC Campus (Opp to Dr. Renugadevi
                        Clinic) Near Arcot Hospital Cuddalore, Tamil Nadu-
                        607001
                      </p>
                      <h4 className="number"> Puducherry Clinic</h4>
                      <p style={{ marginBottom: "40px" }}>
                        Mind Care Clinic, Rani Hospital No: 27, Vazhudavoor Rd,
                        Near Rajiv Gandhi Statue, Pettaiyanchathiram, Aruthra
                        Nagar, Puducherry, 605009
                      </p>
                      <div
                        className="contact-bx contact-footer"
                        style={{ marginBottom: "40px" }}
                      >
                        {/* ! two addresses of mobile  */}
                        <div className="footer-wrapper">
                          <div className="icon">
                            <i className="fas fa-phone-alt"></i>
                          </div>

                          <div className="contact-number">
                            <span>Contact Us</span>
                            <h4 className="number"> +91 9500423778 |</h4>
                            <h4 className="number"> +91 8072367642</h4>
                            <a href="mailto:mindcarecliniccuddalore@gmail.com">
                              mindcarecliniccuddalore@gmail.com
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-6">
                  <div className="widget footer_widget ml-50">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul>
                      <li>
                        <Link to="/about-us">
                          <span>About Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/services">
                          <span>Treatments Offered</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/faq">
                          <span>Faq's</span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/blog-grid">
                          <span>Blogs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/team">
                          <span>Our Team</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/gallery">
                          <span>Gallery</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-6">
                  <div className="widget footer_widget">
                    <h3 className="footer-title">Our Service</h3>
                    <ul>
                      {services?.slice(0, 4)?.map((service) => (
                        <li>
                          <Link to={"/services/" + service.link}>
                            <span>{service.name}</span>
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link to="/services">
                          <span>And Many More...</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="widget widget_form">
                    <h3 className="footer-title">Subscribe</h3>
                    <form
                      className="subscribe-form subscription-form mb-30"
                      action="https://formsubmit.co/mindcarecliniccuddalore@gmail.com"
                      method="POST"
                    >
                      <div className="ajax-message"></div>
                      <div className="input-group">
                        <input
                          name="NewsLetter Subscriber Email"
                          required="required"
                          className="form-control"
                          placeholder="Email Address"
                          type="email"
                        />
                      </div>
                      <button
                        // name="submit"
                        value="Submit"
                        type="submit"
                        className="btn btn-primary shadow w-100"
                      >
                        Subscribe Now
                      </button>
                    </form>
                    <div className="footer-social-link">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/"
                          >
                            <img src={facebook} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/"
                          >
                            <img src={twitter} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <img src={instagram} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer-bottom">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="copyright-text">
                    Copyright © 2023 Design & Developed by{" "}
                    <a
                      href="http://rightlabs.live/"
                      rel="noreferrer"
                      target="_blank"
                      className="text-secondary"
                    >
                      RightLabs
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate1" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        </footer>
      </>
    );
  }
}

export default aboutSection;
